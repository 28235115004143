"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAreaQuery = exports.AreaQuery = void 0;
const standard_query_1 = require("./standard-query");
class AreaQuery extends standard_query_1.StandardQuery {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "slug", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "parentSlug", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "feature", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "listingId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
}
exports.AreaQuery = AreaQuery;
const createAreaQuery = (init) => new AreaQuery().setQuery(init);
exports.createAreaQuery = createAreaQuery;
