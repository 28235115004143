import { BroadcastChannel } from "broadcast-channel";

export type EventMessage = {
  tabId: number;
  type: string;
  payload: any;
};

type Listener = (ev: EventMessage) => void;

export class CrossTabEvents {
  tabId = Date.now();
  channel!: BroadcastChannel<EventMessage>;
  listeners = new Map<string, Set<Listener>>();

  constructor(channelName: string) {
    this.channel = new BroadcastChannel(channelName);
    this.channel.onmessage = (ev) => {
      const handlers = this.listeners.get(ev.type);
      if (!handlers) return;
      for (const handle of handlers) {
        if (!handle) continue;
        handle(ev);
      }
    };
  }

  emit = (type: string, payload: any) => {
    this.channel.postMessage({
      tabId: this.tabId,
      type,
      payload,
    });
  };

  close = () => {
    this.channel.close();
  };

  on = (type: string, handler: Listener) => {
    if (!this.listeners.has(type)) {
      this.listeners.set(type, new Set());
    }
    const handlerSet = this.listeners.get(type);
    handlerSet!.add(handler);
    return () => {
      this.off(type, handler);
    };
  };

  off = (type: string, handler: Listener) => {
    if (!this.listeners.has(type)) return;

    const handlersSet = this.listeners.get(type);
    return handlersSet!.delete(handler);
  };
}

const CHANNEL_NAME = "listatto_app";

export const crossTabEvents = new CrossTabEvents(CHANNEL_NAME);
