"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseQuery = void 0;
const qs = __importStar(require("./qs"));
class BaseQuery {
    assign(q) {
        // @ts-ignore
        q = qs.parse(q);
        Object.getOwnPropertyNames(this).forEach((key) => {
            const val = q[key];
            if (!q.hasOwnProperty(key))
                return;
            this[key] = val;
        });
    }
    setQuery(q) {
        if (q) {
            this.assign(q);
        }
        return this;
    }
    toString() {
        return qs.stringify(this);
    }
    toJSON() {
        return Object.entries(this).reduce((acc, entry) => {
            const [key, val] = entry;
            if (typeof val === "undefined")
                return acc;
            acc[key] = val;
            return acc;
        }, {});
    }
    clone() {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
    }
}
exports.BaseQuery = BaseQuery;
