import {
  EmailSubscriptionQuery,
  NonFunctionProperties,
} from "@listatto/common";
import { fetchApi } from "./fetch";

type EmailSub = Pick<
  NonFunctionProperties<EmailSubscriptionQuery>,
  "email_address" | "query" | "email_type" | "query_json"
>;

export function createEmailSubscription(
  emailSub: Omit<EmailSub, "email_type">
) {
  const sub: EmailSub = {
    ...emailSub,
    email_type: "LISTING",
  };

  return fetchApi("/api/v2/subscriptions", {
    method: "post",
    body: sub,
  });
}

export function fetchEmailSubscriptions() {
  return fetchApi("/api/v2/subscriptions", {});
}

export function deleteEmailSubscriptions(id: string) {
  return fetchApi("/api/v2/subscriptions/:id", {
    method: "delete",
    params: {
      id,
    },
  });
}
