import { MantineProvider } from "@mantine/core";
import "maplibre-gl/dist/maplibre-gl.css";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import "react-day-picker/lib/style.css";

import "@listatto/semantic-ui";

import "../components/DayRangePicker/DayRangePicker.css";
import "../components/ImageCarousel/ImageCarousel.css";
import "../components/ListingCard/ListingCard.css";
import "../components/ListingPage/listing-page.css";
import { AccountStoreProvider } from "../stores/AccountStore";
import { registerGaEvents } from "../util/analytics";
import "./app.css";

// @ts-ignore
function App({ Component, pageProps, ...rest }) {
  useEffect(() => {
    // @ts-ignore
    import("lazysizes/plugins/attrchange/ls.attrchange").then(() => {
      // @ts-ignore
      import("lazysizes");
    });
  }, []);

  const router = useRouter();

  useEffect(() => {
    const cleanup = registerGaEvents();

    return cleanup;
  }, [router.pathname]);

  const { accountStoreInitialState } = pageProps;

  return (
    <>
      <Head>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-112470974-2"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag() {
                dataLayer.push(arguments);
              }
              gtag("js", new Date());
              gtag("config", "UA-112470974-2");
              gtag("config", "AW-807485820");
              `,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              (function (w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
              var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != "dataLayer" ? "&l=" + l : "";
              j.async = true;
              j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
              f.parentNode.insertBefore(j, f);
              })(window, document, "script", "dataLayer", "GTM-PSNL2VZ");
              `,
          }}
        />
      </Head>
      <noscript> You need to enable JavaScript to run this app. </noscript>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-PSNL2VZ"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        />
      </noscript>
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <AccountStoreProvider initialState={accountStoreInitialState}>
          <Component {...pageProps} />
        </AccountStoreProvider>
      </MantineProvider>
    </>
  );
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getServerSideProps = async (appContext) => {
//   // calls page's `getServerSideProps` and fills `appProps.pageProps`
//   const appProps = await App.getServerSideProps(appContext);
//
//   return { ...appProps }
// }

export default App;
