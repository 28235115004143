import React from "react";
import { Modal } from "semantic-ui-react";
import { LoginForm } from "./LoginForm";

interface LoginModalProps {
  show: boolean;
  toggleModal: () => void;
  createMode?: boolean;
}

const LoginModal: React.FC<LoginModalProps> = ({
  show,
  toggleModal,
  createMode = true,
}) => (
  <Modal open={show} onClose={toggleModal} closeIcon>
    <Modal.Content>
      <LoginForm createMode={createMode} />
    </Modal.Content>
  </Modal>
);
export default LoginModal;
