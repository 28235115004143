import React, { useContext, useEffect, useMemo } from "react";
import { enableStaticRendering, observer } from "mobx-react-lite";
import { AccountStore } from "./AccountStore";
import LoginModal from "../../components/LoginModal";

enableStaticRendering(typeof window === "undefined");

// @ts-ignore
const CTX = React.createContext<AccountStore>();

export const useAccountStore = () => useContext(CTX);

const ObservedLoginModal = observer(() => {
  const account = useAccountStore();

  return (
    <LoginModal
      show={account.promptLogin}
      toggleModal={() => account.toggleLogin()}
    />
  );
});

export interface AccountStoreProviderPageProps {
  accountStoreInitialState?: { token: string; isLoggedIn: boolean };
}
interface AccountStoreProviderProps {
  initialState?: { token: string; isLoggedIn: boolean };
}

export const AccountStoreProvider: React.FC<AccountStoreProviderProps> = ({
  children,
  initialState,
}) => {
  const accountStore = useMemo(() => new AccountStore(initialState), []);

  useEffect(() => {
    accountStore.init();
  }, []);

  return (
    <CTX.Provider value={accountStore}>
      <ObservedLoginModal />
      {children}
    </CTX.Provider>
  );
};
