"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createListingQuery = exports.ListingQuery = void 0;
const strings_1 = require("../strings");
const standard_query_1 = require("./standard-query");
class ListingQuery extends standard_query_1.StandardQuery {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "listingId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "mls", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "saleType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "propertyType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "bounds", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "area", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "location", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "listPrice", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "squareFoot", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "den", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "bath", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "bed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "parking", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "exposure", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "liked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "exclude", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "moveInDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "geojson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sold", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "soldDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "soldPrice", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "listedAfter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nearby", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "zoomTo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    toSearchUrl() {
        var _a;
        const txt = (0, strings_1.getPropertyAndSaleTypeDisplayText)(this.propertyType, this.saleType, {
            plural: true,
            sold: this.sold,
        });
        let areas = (_a = this.area) === null || _a === void 0 ? void 0 : _a.join(",");
        if (areas) {
            areas = "in/" + areas;
        }
        else {
            areas = "";
        }
        const qstring = new ListingQuery()
            .setQuery({
            ...this.toJSON(),
            propertyType: undefined,
            saleType: undefined,
            area: undefined,
            fields: undefined,
            listedAfter: undefined,
        })
            .toString();
        const appPath = `/search/${txt.plainKebab}/${areas}${qstring}`;
        return appPath;
    }
}
exports.ListingQuery = ListingQuery;
const createListingQuery = (init) => new ListingQuery().setQuery(init);
exports.createListingQuery = createListingQuery;
