import { useRouter } from "next/router";
import React from "react";
import { Button, ListProps, Grid } from "semantic-ui-react";

interface LoginSocialButtonProps {
  columnProps?: ListProps;
  google?: boolean;
  facebook?: boolean;
  email?: boolean;
}

export const LoginSocialButtons: React.FC<LoginSocialButtonProps> = ({
  columnProps,
  email,
  // facebook,
  google,
}) => {
  const { query, asPath } = useRouter();
  const { redirect } = query;
  const redirectHref = redirect || asPath;

  return (
    <Grid relaxed columns="equal" stackable>
      {google && (
        <Grid.Column {...columnProps}>
          <Button
            as="a"
            href={`/auth/google/?redirect=${redirectHref}`}
            fluid
            size="big"
            color="google plus"
            labelPosition="left"
            icon="google"
            content="Google"
            id={`app::login-google`}
            data-ga-event
          />
        </Grid.Column>
      )}
      {/* {facebook && (
        <Grid.Column {...columnProps}>
          <Button
            as="a"
            href={`/auth/facebook/?redirect=${redirectHref}`}
            fluid
            size="big"
            color="facebook"
            labelPosition="left"
            icon="facebook"
            content="Facebook"
            id={`app::login-facebook`}
            data-ga-event
          />
        </Grid.Column>
      )} */}

      {email && (
        <Grid.Column {...columnProps}>
          <Button
            as="a"
            href={`/register?redirect=${redirectHref}`}
            fluid
            size="big"
            color="grey"
            labelPosition="left"
            icon="mail"
            content="Email"
            id={`app::login-email`}
            data-ga-event
          />
        </Grid.Column>
      )}
    </Grid>
  );
};
