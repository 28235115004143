"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeadRequestTime = exports.LeadRequestDays = exports.LeadRequestStatus = exports.LeadRequestType = void 0;
var LeadRequestType;
(function (LeadRequestType) {
    LeadRequestType["Inquiry"] = "Inquiry";
    LeadRequestType["Booking"] = "Booking";
    LeadRequestType["ThirdParty"] = "ThirdParty";
})(LeadRequestType = exports.LeadRequestType || (exports.LeadRequestType = {}));
var LeadRequestStatus;
(function (LeadRequestStatus) {
    LeadRequestStatus["Pending"] = "pending";
    LeadRequestStatus["Confirmed"] = "confirmed";
    LeadRequestStatus["Complete"] = "complete";
    LeadRequestStatus["Rejected"] = "rejected";
    LeadRequestStatus["Cancelled"] = "cancelled";
})(LeadRequestStatus = exports.LeadRequestStatus || (exports.LeadRequestStatus = {}));
var LeadRequestDays;
(function (LeadRequestDays) {
    LeadRequestDays["Sunday"] = "sunday";
    LeadRequestDays["Monday"] = "monday";
    LeadRequestDays["Tuesday"] = "tuesday";
    LeadRequestDays["Wednesday"] = "wednesday";
    LeadRequestDays["Thursday"] = "thursday";
    LeadRequestDays["Friday"] = "friday";
    LeadRequestDays["Saturday"] = "saturday";
})(LeadRequestDays = exports.LeadRequestDays || (exports.LeadRequestDays = {}));
var LeadRequestTime;
(function (LeadRequestTime) {
    LeadRequestTime["Morning"] = "morning";
    LeadRequestTime["Afternoon"] = "afternoon";
    LeadRequestTime["Evening"] = "evening";
})(LeadRequestTime = exports.LeadRequestTime || (exports.LeadRequestTime = {}));
