import { observer } from "mobx-react-lite";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { SyntheticEvent, useEffect, useState } from "react";
import {
  Dimmer,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  InputOnChangeData,
  Label,
  Loader,
  Menu,
  Segment,
} from "semantic-ui-react";

import { fetchApi } from "../api/fetch";
import { useAccountStore } from "../stores/AccountStore";
import { LoginSocialButtons } from "./LoginSocialButtons";

function regexEmail(email: string) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email.toLowerCase());
}

export const LoginForm: React.FC<{
  createMode: boolean;
  email?: string;
}> = observer((props) => {
  const account = useAccountStore();
  const router = useRouter();

  useEffect(() => {
    if (account.isLoggedIn) {
      setIsLoading(true);
      if (router.query.redirect) {
        router.push(router.query.redirect as string);
        return;
      }
      router.push("/");
    }
  }, [account.isLoggedIn]);

  const [isLoading, setIsLoading] = useState(false);
  const [createMode, setCreateMode] = useState(props.createMode);
  const [formData, setFormData] = useState({
    email: props.email || "",
    password: "",
    confirm: "",
  });
  const [errors, setErrors] = useState<{
    error: boolean;
    emailErr: boolean | React.ReactNode;
    passwordErr: boolean | React.ReactNode;
    confirmErr: boolean | React.ReactNode;
  }>({
    error: false,
    emailErr: false,
    passwordErr: false,
    confirmErr: false,
  });

  const [viewPassword, setViewPassword] = useState(false);

  useEffect(() => {
    setCreateMode(props.createMode);
  }, [props.createMode]);

  const handleChange = (_e: SyntheticEvent, data: InputOnChangeData) => {
    setFormData((f) => ({ ...f, [data.name]: data.value }));
    // @ts-ignore
    if (errors[data.name + "Err"]) {
      setErrors((e) => ({ ...e, [data.name + "Err"]: false, error: false }));
    }
  };

  const validateEmail = async () => {
    const { email } = formData;

    if (regexEmail(email)) {
      const result = await fetchApi("/auth/check-email", {
        method: "post",
        body: {
          email: email,
        },
      });

      if (result) {
        const { providers } = result;
        if (providers && !providers.includes("local")) {
          setErrors((e) => ({
            ...e,
            error: true,
            emailErr: (
              <Label error>
                An account with this email is registered using:{" "}
                {providers.join(", ")}
                <Divider />
                Sign in using one of these methods and create a password!
              </Label>
            ),
          }));
          return true;
        }
      }

      if (!createMode && !result) {
        setErrors((e) => ({
          ...e,
          error: true,
          emailErr: "This account does not exist.",
        }));
        return true;
      }
    } else {
      setErrors((e) => ({
        ...e,
        error: true,
        emailErr: "Please enter a valid email.",
      }));
      return true;
    }
  };

  const validatePassword = () => {
    if (formData.password.length < 6) {
      setErrors((e) => ({
        ...e,
        error: true,
        passwordErr: "Password must be 6 characters or more",
      }));
      return true;
    }
  };

  const validateConfirm = () => {
    if (formData.password !== formData.confirm) {
      setErrors((e) => ({
        ...e,
        error: true,
        confirmErr: "Passwords do not match",
      }));
      return true;
    }
  };

  const toggleCreate = (bool: boolean) => {
    setCreateMode(bool);
    setErrors((e) => ({
      ...e,
      error: false,
      emailErr: false,
      passwordErr: false,
      confirmErr: false,
    }));
  };

  const handleLogin = async () => {
    const { email, password } = formData;
    setIsLoading(true);
    if (await validateEmail()) {
      setIsLoading(false);
      return;
    }
    if (validatePassword()) {
      setIsLoading(false);

      return;
    }
    const { message, token } = await fetchApi("/auth/local", {
      method: "post",
      body: {
        createMode,
        email,
        password,
      },
    });

    if (message) {
      setErrors((e) => {
        return { ...e, passwordErr: message };
      });
    }
    setIsLoading(false);
    if (token) {
      account.reinitialize(token);
    }
  };
  const handleSignUp = async () => {
    const { email, password, confirm } = formData;
    const { emailErr, passwordErr, confirmErr } = errors;

    setIsLoading(true);

    await validateEmail();
    validatePassword();
    validateConfirm();
    if (
      !emailErr &&
      !passwordErr &&
      !confirmErr &&
      password &&
      email &&
      confirm
    ) {
      const { message, token } = await fetchApi("/auth/local", {
        method: "post",
        body: {
          createMode,
          email,
          password,
          confirm,
        },
      });
      if (message) {
        setErrors((e) => {
          return { ...e, passwordErr: message };
        });
      }
      if (token) {
        account.reinitialize(token);
      }
    }
    setIsLoading(false);
  };

  const { confirmErr, emailErr, passwordErr } = errors;

  const { email, password, confirm } = formData;

  return (
    <div>
      <Dimmer active={isLoading || account.isLoading} inverted>
        <Loader inverted content="Loading" />
      </Dimmer>
      <Menu pointing secondary>
        <Menu.Item
          name="Sign Up"
          active={createMode}
          onClick={() => toggleCreate(true)}
        />
        <Menu.Item
          name="Login"
          active={!createMode}
          onClick={() => toggleCreate(false)}
        />
      </Menu>
      <Grid columns="equal" celled="internally" doubling stackable>
        <Grid.Column>
          <Form className="login-form">
            <Form.Field>
              <Form.Input
                name="email"
                label="Email"
                placeholder="Email Address"
                type="email"
                value={email}
                onChange={handleChange}
                onBlur={validateEmail}
                required
                error={emailErr}
              />
            </Form.Field>

            <Form.Field className="login-field">
              <Form.Input
                name="password"
                label="Password"
                type={viewPassword ? "text" : "password"}
                value={password}
                placeholder="Password"
                onChange={handleChange}
                required
                onBlur={createMode ? validatePassword : null}
                error={passwordErr}
                icon={
                  <Icon
                    name="eye"
                    link
                    color={viewPassword ? "black" : "grey"}
                    onClick={() => setViewPassword((t) => !t)}
                  />
                }
              />
            </Form.Field>

            {createMode && (
              <Form.Field className="login-field">
                <Form.Input
                  name="confirm"
                  label="Confirm Password"
                  value={confirm}
                  type={viewPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  onChange={handleChange}
                  required
                  onBlur={createMode ? validateConfirm : null}
                  error={confirmErr}
                  icon={
                    <Icon
                      name="eye"
                      link
                      color={viewPassword ? "black" : "grey"}
                      onClick={() => setViewPassword((t) => !t)}
                    />
                  }
                />
              </Form.Field>
            )}
            {createMode ? (
              <p>
                <small>Password must be longer than 6 characters.</small>
              </p>
            ) : (
              <p>
                <small>
                  <Link href="/forgot-password">Forgot Your Password?</Link>
                </small>
              </p>
            )}

            <Form.Button
              content={createMode ? "Sign Up" : "Login"}
              color="blue"
              onClick={createMode ? handleSignUp : handleLogin}
            />
          </Form>
        </Grid.Column>
        <Grid.Column>
          <Header as="h4">
            Social Login
            <Header.Subheader>
              You can login and sign up with the social providers below.
            </Header.Subheader>
          </Header>
          <LoginSocialButtons columnProps={{ width: 16 }} google facebook />
        </Grid.Column>
      </Grid>
      <Segment size="tiny" basic textAlign="center">
        By registering with Listatto, you agree to receive communications from
        listatto.com and agree to our{" "}
        <a href="https://listatto.com/policy/terms-of-use">Terms of Use</a> and{" "}
        <a href="https://listatto.com/policy/privacy-policy">Privacy Policy</a>.
      </Segment>
    </div>
  );
});
