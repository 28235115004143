"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPropertyAndSaleTypeDisplayText = exports.getListingSaleTypeDisplayText = exports.getListingTypeDisplayText = void 0;
const listing_1 = require("../schema/listing");
const getListingTypeDisplayText = (listingType, opts = { plural: false }) => {
    const { plural } = opts;
    let args = [];
    if (plural)
        args.push("s");
    switch (listingType) {
        case listing_1.ListingPropertyType.Condo:
            return ["Condo", ...args].join("");
        case listing_1.ListingPropertyType.Residential:
            return ["Home", ...args].join("");
        default:
            return [plural ? "Propertie" : "Property", ...args].join("");
    }
};
exports.getListingTypeDisplayText = getListingTypeDisplayText;
const getListingSaleTypeDisplayText = (saleType, opts = { plural: false }) => {
    const { plural } = opts;
    let args = [];
    if (plural)
        args.push("s");
    switch (saleType) {
        case listing_1.ListingSaleType.Lease:
            return ["Rent", ...args].join("");
        case listing_1.ListingSaleType.Sale:
        default:
            return ["Sale", ...args].join("");
    }
};
exports.getListingSaleTypeDisplayText = getListingSaleTypeDisplayText;
const getPropertyAndSaleTypeDisplayText = (propertyType, saleType, opts) => {
    const plural = Boolean(opts === null || opts === void 0 ? void 0 : opts.plural);
    const sold = opts === null || opts === void 0 ? void 0 : opts.sold;
    const propertyTypeText = (0, exports.getListingTypeDisplayText)(propertyType, { plural });
    const saleTypeText = (0, exports.getListingSaleTypeDisplayText)(saleType);
    let plain = [propertyTypeText, "for", saleTypeText];
    if (sold) {
        plain = [propertyTypeText, "Sold"];
    }
    return {
        propertyTypeText,
        saleTypeText,
        plain: plain.filter(Boolean).join(" "),
        plainKebab: plain.filter(Boolean).join("-").toLowerCase(),
    };
};
exports.getPropertyAndSaleTypeDisplayText = getPropertyAndSaleTypeDisplayText;
