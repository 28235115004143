import { StandardQuery, qs } from "@listatto/common";
import fetchWith, { FetchWithMiddleware, InferMiddlewareCtx } from "fetch-with";
import Cookies from "universal-cookie";
import { AUTH } from "../util/consts";
const cookies = new Cookies();
const API_HOST = process.env.API_HOST;
const BUILD_API_HOST = process.env.BUILD_API_HOST;

export const withApiUrl: FetchWithMiddleware = (ctx) => {
  if (!process.browser) {
    ctx.url = (BUILD_API_HOST || API_HOST) + ctx.url;
  }
  return ctx;
};

export const withQuery: FetchWithMiddleware<{ query?: any }> = (ctx) => {
  const query = ctx.options.query;
  if (query) {
    if (query.prototype instanceof StandardQuery) {
      ctx.url += query.toString();
    } else {
      ctx.url += qs.stringify(query);
    }
  }
  return ctx;
};

export const withAuth: FetchWithMiddleware<{ auth?: string }> = (ctx) => {
  if (ctx.options.auth) {
    ctx.options.headers = {
      ...ctx.options.headers,
      [AUTH]: ctx.options.auth,
    };
    return ctx;
  }

  if (!process.browser) {
    return ctx;
  }
  const auth = cookies.get(AUTH);
  if (auth) {
    ctx.options.headers = {
      ...ctx.options.headers,
      [AUTH]: auth,
    };
  }

  return ctx;
};

export const withJsonHeaders: FetchWithMiddleware = (ctx) => {
  ctx.options.headers = {
    ...ctx.options.headers,
    "Content-Type": "application/json",
  };
  return ctx;
};

export const withBody: FetchWithMiddleware<{
  body?: object | string;
  method?: string;
}> = (ctx) => {
  const { body, method } = ctx.options;

  if (method?.toLowerCase() === "post" && body) {
    if (typeof body === "object") {
      ctx.options.body = JSON.stringify(body);
    }
  }

  return ctx;
};

const reParams = /(\:[a-zA-Z]+)/g;

export const withParams: FetchWithMiddleware<{
  params?: Record<string, string>;
}> = (ctx) => {
  const { params } = ctx.options;
  if (params) {
    const matchParams = ctx.url.match(reParams);
    if (!matchParams) return ctx;
    for (const key of matchParams) {
      const val = params[key.replace(":", "")];
      ctx.url = ctx.url.replace(key, val);
    }
  }

  return ctx;
};

type FetchOptions = InferMiddlewareCtx<typeof withQuery> &
  InferMiddlewareCtx<typeof withBody> &
  InferMiddlewareCtx<typeof withAuth> &
  InferMiddlewareCtx<typeof withParams>;

export function fetchApi<T = any>(
  url: string,
  options: FetchOptions,
  ...middleware: FetchWithMiddleware[]
): Promise<T> {
  return fetchWith<FetchOptions>(
    url,
    options,
    withJsonHeaders,
    withAuth,
    withApiUrl,
    withQuery,
    withParams,
    withBody,
    ...middleware
  ).then((res) => res.json());
}
