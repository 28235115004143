import {
  EmailSubscription,
  Lead,
  LeadRequest,
  LeadRequestStatus,
  User,
} from "@listatto/common";
import { makeAutoObservable, runInAction } from "mobx";
import Cookies from "universal-cookie";
import debounce from "lodash/debounce";

import {
  fetchCurrentUser,
  saveCurrentUser,
  fetchLead,
  putLead,
  LeadFetchRequest,
  LeadRequests,
} from "../../api/user";
import {
  deleteEmailSubscriptions,
  fetchEmailSubscriptions,
} from "../../api/subscriptions";

import { CrossTabEvents, crossTabEvents } from "../../api/cross-tab-events";
import { AUTH } from "../../util/consts";

function getCookieDomain() {
  const hostArr = document.location.hostname.split(".");
  let host = `${hostArr.slice(hostArr.length - 2).join(".")}`;
  if (hostArr.length > 1) {
    host = "." + host;
  }
  return host;
}

export class AccountStore {
  cookies = new Cookies();
  token: string = this.getAuthCookie();
  isLoggedIn = false;
  user: User | null = null;
  lead: Lead | null = null;
  leadRequest: LeadRequest | null = null;
  leadRequests: LeadRequests | null = null;
  emailSubscriptions: EmailSubscription[] | null = null;
  isLoading = false;
  promptLogin = false;
  channel: CrossTabEvents = crossTabEvents;

  constructor(initialState?: { token: string; isLoggedIn: boolean }) {
    if (initialState) {
      this.token = initialState.token;
      this.isLoggedIn = initialState.isLoggedIn;
    }

    makeAutoObservable(this);

    this.channel.on(
      "ACCOUNT_UPDATE",
      debounce((ev) => {
        if (ev.tabId === this.channel.tabId) return;
        runInAction(() => {
          Object.assign(this, ev.payload);
        });
      }, 250)
    );
  }

  getAuthCookie() {
    return this.cookies.get(AUTH);
  }

  init() {
    if (typeof this.token !== "string") {
      this.logout();
      return;
    }
    if (this.token) {
      this.fetchUser();
    }
  }

  reinitialize = (token: string) => {
    this.token = token;
    this.cookies.set(AUTH, token, {
      path: "/",
      domain: getCookieDomain(),
    });
    this.init();
  };

  async fetchUser() {
    runInAction(() => {
      this.isLoading = true;
    });
    const user = await fetchCurrentUser(this.token);
    if (!user) return this.logout();
    runInAction(() => {
      this.user = user;
      this.isLoggedIn = true;
      this.isLoading = false;
    });

    this.channel.emit("ACCOUNT_UPDATE", {
      isLoggedIn: this.isLoggedIn,
      user: JSON.parse(JSON.stringify(this.user)),
    });
    this.getLead();
  }

  logout = () => {
    this.cookies.remove(AUTH, {
      path: "/",
      domain: getCookieDomain(),
    });
    this.token = "";
    this.user = null;
    this.isLoggedIn = false;
    this.lead = null;
    this.leadRequest = null;
    this.leadRequests = null;
    this.emailSubscriptions = null;

    this.channel.emit("ACCOUNT_UPDATE", {
      isLoggedIn: false,
      user: null,
    });
  };

  toggleLogin = (next?: boolean) => {
    this.promptLogin = next || !this.promptLogin;
  };

  updateUser = (nextUser: Partial<User>) => {
    this.user = {
      ...this.user,
      ...nextUser,
    } as User;

    return this.saveUser(nextUser);
  };

  saveUser = async (user: Partial<User>) => {
    if (!this.user || !user) return null;
    const res = await saveCurrentUser(user ? user : this.user);
    runInAction(() => {
      this.token = res.token;
      this.user = res.user;
    });
    return this.user;
  };

  getLead = async (opts?: { email?: string; omitRequests?: boolean }) => {
    runInAction(() => {
      this.isLoading = true;
    });
    const res = await fetchLead(opts?.email, opts?.omitRequests);
    runInAction(() => {
      this.isLoading = false;

      if (!res) return;
      this.lead = res.lead;
      this.leadRequests = res.request as LeadRequests;
      if (this.leadRequests) {
        const lastLead = this.leadRequests.data[0];
        if (lastLead && lastLead.status == LeadRequestStatus.Pending) {
          this.leadRequest = lastLead;
        }
      }
    });
  };

  putLead = async (data: LeadFetchRequest) => {
    runInAction(() => {
      this.isLoading = true;
    });
    const res = await putLead(data);
    runInAction(() => {
      this.isLoading = false;
      this.lead = res.lead;
      this.leadRequest = res.request as LeadRequest;
    });
    return res;
  };

  cancelLead = async (leadRequestId: number) => {
    runInAction(() => (this.isLoading = true));
    if (!this.lead || !this.leadRequests) return null;
    const leadRequest = this.leadRequests.data.find(
      (lr) => lr.id === leadRequestId
    );
    leadRequest!.status = LeadRequestStatus.Cancelled;

    await this.putLead({
      lead: this.lead,
      request: leadRequest!,
    });

    runInAction(() => {
      this.isLoading = false;
    });
  };

  getEmailSubscriptions = async () => {
    runInAction(() => {
      this.isLoading = true;
    });
    const { data } = await fetchEmailSubscriptions();
    runInAction(() => {
      this.emailSubscriptions = data;
      this.isLoading = false;
    });
  };

  deleteEmailSubscriptions = async (id: string) => {
    const { data } = await deleteEmailSubscriptions(id);
    if (data) {
      runInAction(() => {
        if (this.emailSubscriptions) {
          this.emailSubscriptions = this.emailSubscriptions.filter(
            (e) => e.id !== id
          );
        }
      });
    }
  };
}
