"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListingAvailability = exports.ListingPropertyType = exports.ListingSaleType = void 0;
var ListingSaleType;
(function (ListingSaleType) {
    ListingSaleType["Sale"] = "SALE";
    ListingSaleType["Lease"] = "LEASE";
})(ListingSaleType = exports.ListingSaleType || (exports.ListingSaleType = {}));
var ListingPropertyType;
(function (ListingPropertyType) {
    ListingPropertyType["All"] = "All";
    ListingPropertyType["Condo"] = "CondoProperty";
    ListingPropertyType["Residential"] = "ResidentialProperty";
})(ListingPropertyType = exports.ListingPropertyType || (exports.ListingPropertyType = {}));
var ListingAvailability;
(function (ListingAvailability) {
    ListingAvailability["Instant"] = "INSTANT";
    ListingAvailability["Maybe"] = "MAYBE";
    ListingAvailability["Available"] = "AVAILABLE";
    ListingAvailability["Expired"] = "EXPIRED";
})(ListingAvailability = exports.ListingAvailability || (exports.ListingAvailability = {}));
