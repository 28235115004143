"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createStandardQuery = exports.StandardQuery = void 0;
const base_query_1 = require("./base-query");
class StandardQuery extends base_query_1.BaseQuery {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "fields", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "limit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "offset", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "order", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "all", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
}
exports.StandardQuery = StandardQuery;
const createStandardQuery = (init) => new StandardQuery().setQuery(init);
exports.createStandardQuery = createStandardQuery;
