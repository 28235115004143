"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createEmailSubscriptionQuery = exports.EmailSubscriptionQuery = void 0;
const standard_query_1 = require("./standard-query");
class EmailSubscriptionQuery extends standard_query_1.StandardQuery {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "user_id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "email_address", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "email_type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "email_frequency", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "query", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "query_json", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "created_at", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "deleted_at", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
}
exports.EmailSubscriptionQuery = EmailSubscriptionQuery;
const createEmailSubscriptionQuery = (init) => new EmailSubscriptionQuery().setQuery(init);
exports.createEmailSubscriptionQuery = createEmailSubscriptionQuery;
