"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMultiSearchQuery = exports.MultiSearchQuery = void 0;
const standard_query_1 = require("./standard-query");
class MultiSearchQuery extends standard_query_1.StandardQuery {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "q", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "d", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
}
exports.MultiSearchQuery = MultiSearchQuery;
const createMultiSearchQuery = (init) => new MultiSearchQuery().setQuery(init);
exports.createMultiSearchQuery = createMultiSearchQuery;
