import { useMemo } from "react";

export const getGTag = () => {
  if (!window) return null;
  const gtag = window.gtag;
  if (!gtag) return null;
  return gtag;
};

export const useGtag = () => {
  return useMemo(() => getGTag(), [window, window?.gtag]);
};

export function registerGaEvents() {
  const nodes = document.querySelectorAll("*[id][data-ga-event]");
  const cleanup: (() => void)[] = [];
  nodes.forEach((n) => {
    const handleEvent = () => {
      const gtag = getGTag();
      if (!gtag) return;
      const dataEventName = n.getAttribute("data-ga-event")!;
      const hasEventname =
        dataEventName !== "true" && dataEventName !== "false";

      const eventName = hasEventname ? dataEventName : n.id;

      gtag("event", eventName, {
        event_label: n.getAttribute("data-ga-event-label"),
        event_category: n.getAttribute("data-ga-event-category"),
      });
    };

    switch (n.tagName) {
      case "BUTTON":
      case "ANCHOR":
        {
          n.addEventListener("click", handleEvent);

          cleanup.push(() => n.removeEventListener("click", handleEvent));
        }
        break;
      case "FORM": {
        n.addEventListener("submit", handleEvent);
        cleanup.push(() => n.removeEventListener("submit", handleEvent));
      }
    }
  });
  return () => cleanup.forEach((fn) => fn());
}
