import { Lead, User, LeadRequest, Pagination } from "@listatto/common";
import { AUTH } from "../util/consts";
import { fetchApi } from "./fetch";

interface GetCurrentUser {
  token: string;
  user: User;
}

export const fetchCurrentUser = async (token: string): Promise<User> => {
  const { user } = await fetch("/api/users/me", {
    headers: {
      [AUTH]: token,
    },
  }).then((res) => res.json() as Promise<GetCurrentUser>);

  return user;
};

export const saveCurrentUser = (
  userData: Partial<User>
): Promise<GetCurrentUser> => {
  return fetchApi("/api/users/me", {
    method: "post",
    body: userData,
  });
};

export const fetchLead = (
  email?: string,
  omitRequests?: boolean
): Promise<LeadFetchResponse | null> => {
  return fetchApi("/api/v2/lead", {
    query: {
      email,
      omitRequests,
    },
  });
};

export interface LeadFetchRequest {
  lead: Lead;
  request: LeadRequest;
}
export interface LeadRequests {
  meta: Pagination;
  data: LeadRequest[];
}
export type LeadFetchResponse = {
  lead: Lead;
  request: LeadRequests | LeadRequest;
};

export const putLead = (data: LeadFetchRequest): Promise<LeadFetchResponse> => {
  return fetchApi("/api/v2/lead", {
    method: "post",
    body: data,
  });
};
