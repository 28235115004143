"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createListingAreaStatsQuery = exports.ListingAreaStatsQuery = void 0;
const standard_query_1 = require("./standard-query");
class ListingAreaStatsQuery extends standard_query_1.StandardQuery {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "slug", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "year_month", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "property_type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sale_type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
}
exports.ListingAreaStatsQuery = ListingAreaStatsQuery;
const createListingAreaStatsQuery = (init) => new ListingAreaStatsQuery().setQuery(init);
exports.createListingAreaStatsQuery = createListingAreaStatsQuery;
